import { Box, TextField, Button, Grid, Typography, Snackbar } from "@mui/material";
import { useState } from "react";
import { useUpdateClientMutation } from "../../../store/clients/services"; // Adjust path as needed

const ClientInfo = ({ id, phone, email, fullname }) => {
  const [editableFullname, setEditableFullname] = useState(fullname);
  const [editablePhone, setEditablePhone] = useState(phone);
  const [editableEmail, setEditableEmail] = useState(email || "");
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);  // State for handling error messages
  const [successMessage, setSuccessMessage] = useState(null); // State for success message

  const [updateClient, { isLoading, isSuccess, isError }] = useUpdateClientMutation();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const validateForm = () => {
    // Trim the input fields
    const trimmedFullname = editableFullname.trim();
    const trimmedPhone = editablePhone.trim();
    const trimmedEmail = editableEmail.trim();

    // Validate Fullname (mandatory, no numbers, no leading/trailing spaces)
    if (!trimmedFullname) {
      return "Please enter a valid name.";
    }
    if (/\d/.test(trimmedFullname)) {
      return "Name should not contain numbers.";
    }
    if (/^\s|\s$/.test(trimmedFullname)) {
      return "Name should not have leading or trailing whitespace.";
    }

    // Validate Phone (mandatory, no spaces, valid phone format)
    if (!trimmedPhone || !/^\d{10,15}$/.test(trimmedPhone)) {
      return "Please enter a valid phone number with 10 to 15 digits, without spaces or the '+' sign.";
    }

    // Email validation (optional)
    if (trimmedEmail && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail)) {
      return "Please enter a valid email address.";
    }

    // If all validations pass, return null
    return null;
  };

  const handleSave = async () => {
    const validationError = validateForm();
    if (validationError) {
      setError(validationError); // Show error message if validation fails
      return;
    }

    try {
      // Trim values before sending
      const trimmedFullname = editableFullname.trim();
      const trimmedPhone = editablePhone.trim();
      const trimmedEmail = editableEmail.trim();

      await updateClient({ id, fullname: trimmedFullname, phone: trimmedPhone, email: trimmedEmail }).unwrap();
      console.log("Client Info Updated");
      console.log("Phone:", trimmedPhone);

      setIsEditing(false);
      setSuccessMessage("Client information updated successfully!");
      setError(null);  // Clear any previous error
    } catch (error) {
      console.error("Failed to update client:", error);  // Log the full error object
      if (error?.message) {
        setError(error.message);  // Display error message from API response if available
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <Box mt={2} mb={2} px={2} position="relative">
      <Typography variant="h6" mb={2} sx={{ fontWeight: 'bold' }}>
        Informations client
      </Typography>
      <Grid container spacing={3} alignItems="center">
        {/* Fullname Field */}
        <Grid item sm={6} xs={12}>
          <Typography variant="subtitle1" mb={1} sx={{ fontWeight: 'bold' }}>Nom</Typography>
          <TextField
            value={editableFullname}
            onChange={(e) => setEditableFullname(e.target.value)}
            placeholder="Nom du client"
            fullWidth
            size="small"
            InputProps={{
              style: { textAlign: 'center', fontSize: '0.875rem' },
            }}
            disabled={!isEditing}
          />
        </Grid>

        {/* Phone Field */}
        <Grid item sm={6} xs={12}>
          <Typography variant="subtitle1" mb={1} sx={{ fontWeight: 'bold' }}>Téléphone</Typography>
          <TextField
            value={editablePhone}
            onChange={(e) => setEditablePhone(e.target.value)}
            placeholder="Numéro de téléphone"
            fullWidth
            size="small"
            InputProps={{
              style: { textAlign: 'center', fontSize: '0.875rem' },
            }}
            disabled={!isEditing}
          />
        </Grid>

        {/* Email Field */}
        <Grid item sm={6} xs={12}>
          <Typography variant="subtitle1" mb={1} sx={{ fontWeight: 'bold' }}>E-mail</Typography>
          <TextField
            value={editableEmail}
            onChange={(e) => setEditableEmail(e.target.value)}
            placeholder="Adresse email"
            fullWidth
            size="small"
            InputProps={{
              style: { textAlign: 'center', fontSize: '0.875rem' },
            }}
            disabled={!isEditing}
          />
        </Grid>

        {/* Edit and Save Buttons */}
        <Grid item sm={6} xs={12}>
          <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2} mt={2}>
            <Button
              variant="outlined"
              onClick={handleEdit}
              disabled={isEditing}
              sx={{ width: '100px' }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={!isEditing || isLoading}
              sx={{ width: '100px' }}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Display error message */}
      {error && (
        <Snackbar
          open={Boolean(error)}
          message={error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        />
      )}

      {/* Optionally, show success message */}
      {successMessage && (
        <Snackbar
          open={Boolean(successMessage)}
          message={successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage(null)}
        />
      )}
    </Box>
  );
};

export default ClientInfo;
