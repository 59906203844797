import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import useFormClient from "hooks/useFormClient";

import {
  H6,
  FlexBox,
  AppTextField,
  LoadingButton,
  FlexBetween,
} from "components";
import { useGetClienByPhoneQuery } from "store/clients/services";
import { useEffect, useState } from "react";
import { convertDate } from "utils/dateUtils";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { isValidNumber } from "libphonenumber-js";
import { subMonths, isAfter, startOfToday } from "date-fns";
import { useBookingsQuery } from "../../../store/bookings/services";

const ClientForm = ({ edit, data, onClose, handleCallback }) => {
  const [skip, setSkip] = useState(true);
  const [clientId, setClientId] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false); // Track phone validity status
  const { data: bookingsData } = useBookingsQuery();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
    validateForm,
  } = useFormClient(clientId || data?.id, data, handleCallback);

  const { data: client, isLoading } = useGetClienByPhoneQuery(values.phone, {
    skip,
  });

  useEffect(() => {
    setSkip(true);
    if (!edit) {
      if (client) {
        setClientId(client.id);
        setFieldValue("email", client.email || "");
        setFieldValue("fullname", client.fullname);
        setFieldValue("professional", client.professional);
        validateForm({
          // email: client.email,
          fullname: client.fullname,
          phone: values.phone,
        });
      } else {
        setClientId();
        setFieldValue("email", "");
        setFieldValue("fullname", "");
        // setFieldValue("professional", false);
      }
    }
    console.log("client", client);
    // eslint-disable-next-line
  }, [client]);

  const validatePhone = (phone, countryCode) => {
    if (!countryCode) return false; // Ensure countryCode is defined before validation
    // Validate the phone number using the selected country code
    const valid = isValidNumber(phone, countryCode.toUpperCase());
    setIsPhoneValid(valid); // Set phone validity status
    return valid;
  };

  const clientBookings =
    bookingsData?.filter((booking) => booking.clientId === client?.id) || [];

  const threeMonthsAgo = subMonths(startOfToday(), 3);

  // Filter for bookings that are after threeMonthsAgo
  const filteredBookings = clientBookings.filter((booking) =>
    isAfter(new Date(booking.checkin), threeMonthsAgo)
  );

  const sortedBookings = filteredBookings
    .sort((a, b) => new Date(b.checkin) - new Date(a.checkin)) // Sort by checkin date descending
    .slice(0, 3); // Get the latest 3 reservations

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className="main-form">
        <Grid item xs={12}>
          <H6 mb={1}>Téléphone</H6>

          <FlexBetween gap={2}>
            <ReactPhoneInput
              country={"ma"} // Default country Morocco
              value={values.phone}
              onChange={(value, countryData) => {
                handleChange({ target: { name: "phone", value } });

                // Get the country code dynamically
                const countryCode = countryData.countryCode;
                // Now validate the phone number using the correct country code
                validatePhone(value, countryCode);
              }}
              inputStyle={{ width: "100%" }} // Style the input
              disabled={edit && data.client?.phone}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
            />
            <Button variant="outlined" onClick={() => setSkip(false)}>
              Check
            </Button>
            <LoadingButton
              fullWidth
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => {
                handleCallback(values); // Directly call the callback with the current form values
                console.log(
                  "Sur Place button clicked: Form submitted without validation!"
                );
                console.log("Form values:", values);
              }}
            >
              Sur Place
            </LoadingButton>
          </FlexBetween>
        </Grid>

        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Nom Complet</H6>
          <AppTextField
            fullWidth
            size="small"
            name="fullname"
            placeholder="John"
            onChange={handleChange}
            value={values.fullname}
            disabled={(edit && data.client?.fullname) || isLoading}
            error={Boolean(errors.fullname && touched.fullname)}
            helperText={touched.fullname && errors.fullname}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Email</H6>
          <AppTextField
            fullWidth
            size="small"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="uilib@gmail.com"
            disabled={(edit && data.client?.email) || isLoading}
            error={Boolean(errors.email && touched.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>
        {client && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", marginTop: 3 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
                borderRadius: 2,
                bgcolor: "#757C92", // Custom grey color
                fontWeight: "bold",
                color: "white",
                marginBottom: 2,
              }}
            >
              <Box>Réservations Récentes</Box>
              <Box
                sx={{
                  padding: "4px 8px",
                  borderRadius: 1,
                  bgcolor: client.isElite ? "gold" : "gray", // Gold for elite, gray otherwise
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {client.isElite ? "Elite" : "Non-Elite"}
              </Box>
            </Box>

            <TableContainer component={Paper} sx={{ paddingLeft: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Pax</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedBookings.length > 0 ? (
                    sortedBookings.map((booking) => (
                      <TableRow key={booking.id}>
                        <TableCell>{client.fullname || "N/A"}</TableCell>
                        <TableCell>
                          {booking.tags?.map((tag) => tag.name).join(", ") ||
                            "N/A"}
                        </TableCell>
                        <TableCell>{convertDate(booking.checkin)}</TableCell>
                        <TableCell>
                          {(booking.adults || 0) + (booking.children || 0)}
                        </TableCell>
                        <TableCell>{booking.status}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="body2">
                          Aucune réservation
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      <FlexBox justifyContent="flex-end" gap={2} marginTop={3}>
        <LoadingButton
          fullWidth
          variant="outlined"
          onClick={onClose}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          Enregistrer
        </LoadingButton>
      </FlexBox>
    </form>
  );
};

export default ClientForm;
