import { useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import ClientInfo from "./ClientInfo";
import ClientForm from "../client/ClientForm";
import { setClient } from "store/clients/slices";

const ClientSection = ({ edit, data, handleBack, handleNext }) => {
  const dispatch = useDispatch();

  // Set initial state for client details
  const [fullname, setFullname] = useState(data?.client?.fullname || data?.Partner?.name || "");
  const [phone, setPhone] = useState(data?.client?.phone || data?.Partner?.phone || "");
  const [email, setEmail] = useState(data?.client?.email || data?.Partner?.email || "");

  // Extract client or partner id
  const clientId = data?.client?.id || data?.Partner?.id;

  const handleCallback = (res) => {
    handleNext();
    dispatch(setClient(res));
  };

  return (
    <Grid container spacing={2}>
      {edit ? (
        <ClientInfo
          id={clientId} // Pass the id here
          fullname={fullname}
          setFullname={setFullname}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
        />
      ) : (
        <Grid item xs={12}>
          <ClientForm onClose={handleBack} handleCallback={handleCallback} />
        </Grid>
      )}
    </Grid>
  );
};

export default ClientSection;
