// ** Third Party Components
import * as Yup from "yup";
import { useFormik } from "formik";

// ** Actions
import {
  useAddBookingMutation,
  useUpdateBookingMutation,
} from "store/bookings/services";
import toast from "react-hot-toast";

const useFormBooking = (edit, data, handleCallback) => {
  const [addBooking] = useAddBookingMutation();
  const [updateBooking] = useUpdateBookingMutation();
  console.log("data", data);
  console.log("edit", edit);
  console.log("handleCallback", handleCallback);
  const initialValues = {
    note: data?.note || "",
    adults: data?.adults || 1,
    children: data?.children || 0,
    status: data?.status || "pending",
    checkin: data?.checkin,
    checkout: data?.checkout,
    tablesList: data?.tablesList || "",
    tags: data?.tags?.map((tag) => tag.id) || [],
  };

  const fieldValidationSchema = Yup.object().shape({
    checkin: Yup.string().required("Check In is Required!"),
    adults: Yup.number()
    .min(1, "Le nombre d'adultes doit être au moins 1")
    .required("Le nombre d'adultes est requis!"),
  });

  return useFormik({
    initialValues: initialValues,
    validationSchema: fieldValidationSchema,
    onSubmit: async (values) => {
      try {
        if (edit) {
          const res = await updateBooking({
            id: data.id,
            ...values,
          }).unwrap();
          handleCallback(res);
          toast.success("Modification réussie !");
        } else {
          const res = await addBooking({ ...values }).unwrap();
          handleCallback(res);
          toast.success("Création réussie !");
          window.location.reload();
        }
      } catch (error) {
        toast.error(error.data?.message ?? "Quelque chose ne va pas ?");
        throw new Error("error");
      }
    },
  });
};

export default useFormBooking;
