import { apiSlice } from "store/api";

export const statisticsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    statistics: builder.query({
      query: (params = {}) => {
        const {
          searchQuery = "",
          startDate = "",
          endDate = "",
          canal = "",
          status = "",
          tagIds = [],
        } = params;

        const searchParams = new URLSearchParams();

        if (searchQuery) searchParams.append("searchQuery", searchQuery);
        if (startDate) searchParams.append("startDate", startDate);
        if (endDate) searchParams.append("endDate", endDate);
        if (canal) searchParams.append("canal", canal);
        if (status) searchParams.append("status", status);
        if (tagIds.length > 0) {
          tagIds.forEach(tagId => searchParams.append("tagIds[]", tagId));
        }

        const queryString = searchParams.toString();
        return `statistics/bookings${queryString ? `?${queryString}` : ''}`;
      },
      providesTags: ["bookings"],
    }),
  }),
});

export const { useStatisticsQuery } = statisticsApi;
