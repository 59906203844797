import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  H6,
  FlexBox,
  AppSelect,
  AppTextField,
  LoadingButton,
} from "components";
import useFormBooking from "hooks/useFormBooking";
import { clientSelector } from "store/clients/slices";
import { getUserData } from "store/auth/slices";
import {
  useGetTagsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
} from "store/bookings/services";
import toast from "react-hot-toast";

dayjs.extend(utc);

const ReservationForm = ({ edit, data, onClose, handleCallback }) => {
  const user = useSelector(getUserData);
  const { client } = useSelector(clientSelector);
  const {
    data: tags,
    isLoading: tagsLoading,
    isError: tagsError,
    refetch: refetchTags,
  } = useGetTagsQuery();
  const [newTag, setNewTag] = useState("");
  const [createTag] = useCreateTagMutation();
  const [deleteTag] = useDeleteTagMutation();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    isSubmitting,
  } = useFormBooking(edit, data, handleCallback);

  useEffect(() => {
    setFieldValue("clientId", client.id);
  }, [client]);
  const statusOption =
    user.role === "admin" ||
    user.role === "receptionist" ||
    user.role === "restaurateur" ||
    user.role === "cashier" ||
    user.role === "marketer"
      ? [
          {
            value: "pending",
            label: "En attente",
          },
          {
            value: "canceled",
            label: "Annulé",
          },
          {
            value: "confirmed",
            label: "Confirmé",
          },
          {
            value: "installed",
            label: "Installé",
          },
          {
            value: "noshow",
            label: "No Show",
          },
          {
            value: "gone",
            label: "Reparti",
          },
          {
            value: "unsatisfied",
            label: "Insatisfait",
          },
        ]
      : [
          {
            value: "pending",
            label: "En attente",
          },
          {
            value: "canceled",
            label: "Annulé",
          },
          {
            value: "confirmed",
            label: "Confirmé",
          },
        ];
  const CanalOption =
    user.role === "admin" ||
    user.role === "receptionist" ||
    user.role === "restaurateur" ||
    user.role === "cashier" ||
    user.role === "marketer"
      ? [
          { value: "website", label: "Website" },
          { value: "instagram", label: "Instagram" },
          { value: "facebook", label: "Facebook" },
          { value: "google_ads", label: "Google Ads" },
          { value: "whatsapp", label: "WhatsApp" },
          { value: "sms", label: "SMS" },
          { value: "qr_code", label: "QR Code" },
          { value: "receptionist", label: "Receptionist" },
          { value: "salespeople", label: "Salespeople" },
          { value: "tripadvisor", label: "Tripadvisor" },
          { value: "email", label: "Email" },
        ]
      : [
          { value: "website", label: "Website" },
          { value: "instagram", label: "Instagram" },
          { value: "facebook", label: "Facebook" },
          { value: "google_ads", label: "Google Ads" },
          { value: "tripadvisor", label: "Tripadvisor" },
        ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className="main-form">
        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Adultes</H6>
          <AppTextField
            fullWidth
            size="small"
            type="number"
            name="adults"
            value={values.adults}
            onChange={handleChange}
            error={Boolean(errors.adults && touched.adults)}
            helperText={touched.adults && errors.adults}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Enfant</H6>
          <AppTextField
            fullWidth
            size="small"
            type="number"
            name="children"
            value={values.children}
            onChange={handleChange}
            error={Boolean(errors.children && touched.children)}
            helperText={touched.children && errors.children}
          />
        </Grid>

        {!edit || (edit && (user.role === "admin" || user.role === "marketer")) ? ( // Show DateTimePicker for non-edit mode or in edit mode for admin users
          <Grid item sm={6} xs={12}>
            <H6 mb={1}>Check In</H6>
            <DateTimePicker
              disablePast
              value={
                values.checkin ? dayjs(values.checkin) : dayjs().startOf("day") // Use current day if no checkin value is present
              }
              onChange={(newValue) => {
                setFieldValue(
                  "checkin",
                  newValue ? newValue.toISOString() : null
                );
              }}
              disableIgnoringDatePartForTimeValidation
              shouldDisableClock={(time, view) => {
                if (view === "hours") {
                  return time >= 2 && time < 20;
                }
                return false;
              }}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  error: Boolean(errors.checkin && touched.checkin),
                  helperText: touched.checkin && errors.checkin,
                },
              }}
            />
          </Grid>
        ) : null}

        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Statut</H6>
          <AppSelect
            fullWidth
            size="small"
            name="status"
            value={values.status}
            onChange={handleChange}
            options={statusOption}
          />
        </Grid>

        {!edit || (edit && user.role === "admin") ? ( // Show AppSelect for non-edit mode or in edit mode for admin users
          <Grid item sm={6} xs={12}>
            <H6 mb={1}>Canal</H6>
            <AppSelect
              fullWidth
              size="small"
              name="canal"
              value={values.canal}
              onChange={handleChange}
              options={CanalOption}
            />
          </Grid>
        ) : null}

        <Grid item sm={6} xs={12}>
          {values.status !== "canceled" &&
            values.status !== "noshow" &&
            (user.role === "receptionist" ||
              user.role === "restaurateur" ||
              user.role === "admin" ||
              user.role === "marketer") && (
              <>
                <H6 mb={1}>Table</H6>
                <AppTextField
                  fullWidth
                  size="small"
                  name="tablesList"
                  placeholder="Table"
                  onChange={handleChange}
                  value={values.tablesList}
                  error={Boolean(errors.table && touched.table)}
                  helperText={touched.table && errors.table}
                />
              </>
            )}
        </Grid>

        {(!data || data.client) && (
          <Grid item sm={6} xs={12}>
            <H6 mb={1}>Tags</H6>
            {tagsLoading ? (
              <div>Loading...</div>
            ) : tagsError ? (
              <div>Error loading tags.</div>
            ) : (
              <AppSelect
                fullWidth
                multiple
                delete
                size="small"
                name="tags"
                value={values.tags}
                onChange={(e) => {
                  setFieldValue("tags", e.target.value);
                }}
                onDelete={async (tagId) => {
                  return await deleteTag(tagId)
                    .unwrap()
                    .then(() => {
                      toast.success("Tag supprimé avec succès");
                      if (values.tags.includes(tagId)) {
                        setFieldValue(
                          "tags",
                          values.tags.filter((tag) => tag !== tagId)
                        );
                      }
                      refetchTags();
                    })
                    .catch(() => {
                      toast.error("Erreur lors de la suppression du tag");
                    });
                }}
                options={
                  tags.map((tag) => ({
                    value: tag.id,
                    label: tag.name,
                    disabled: values.tags.includes(tag.id),
                  })) || []
                }
              />
            )}
            <Box
              container
              spacing={2}
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <AppTextField
                fullWidth
                size="small"
                name="newTag"
                placeholder="Nouveau tag"
                onChange={(e) => setNewTag(e.target.value)}
                value={newTag}
              />
              <LoadingButton
                variant="contained"
                onClick={async () => {
                  if (!newTag) return;
                  return await createTag({
                    name: newTag,
                  })
                    .unwrap()
                    .then(() => {
                      toast.success("Tag ajouté avec succès");
                      setNewTag("");
                      refetchTags();
                    })
                    .catch(() => {
                      toast.error("Erreur lors de l'ajout du tag");
                    });
                }}
              >
                Ajouter
              </LoadingButton>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <H6 mb={1}>Note</H6>
          <AppTextField
            rows={3}
            multiline
            fullWidth
            name="note"
            value={values.note}
            onChange={handleChange}
            error={Boolean(errors.note && touched.note)}
            helperText={touched.note && errors.note}
          />
        </Grid>
      </Grid>

      <FlexBox justifyContent="flex-end" gap={2} marginTop={3}>
        <LoadingButton
          fullWidth
          variant="outlined"
          onClick={onClose}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isSubmitting}
          // disabled={!isValid || isSubmitting}
        >
          Enregistrer
        </LoadingButton>
      </FlexBox>
    </form>
  );
};

export default ReservationForm;
