// DropdownFilter.jsx

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Chip, Checkbox, ListItemText, Box } from '@mui/material';
import { useGetTagsQuery } from 'store/bookings/services'; // Adjust the import path according to your project structure

// Canal Dropdown Component
export const CanalDropdown = ({ selectedCanal, setSelectedCanal }) => (
  <FormControl sx={{ minWidth: 250, maxWidth: 350 }}>
    <InputLabel>Canal</InputLabel>
    <Select
      value={selectedCanal}
      onChange={(e) => setSelectedCanal(e.target.value)}
      label="Canal"
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="website">Website</MenuItem>
      <MenuItem value="instagram">Instagram</MenuItem>
      <MenuItem value="facebook">Facebook</MenuItem>
      <MenuItem value="google_ads">Google Ads</MenuItem>
      <MenuItem value="whatsapp">WhatsApp</MenuItem>
      <MenuItem value="sms">SMS</MenuItem>
      <MenuItem value="qr_code">QR Code</MenuItem>
      <MenuItem value="receptionist">Receptionist</MenuItem>
      <MenuItem value="salespeople">Salespeople</MenuItem>
      <MenuItem value="email">Email</MenuItem>
    </Select>
  </FormControl>
);

// Tags Dropdown Component
export const TagsDropdown = ({ selectedTags, setSelectedTags }) => {
  const { data: tags, isLoading: tagsLoading, isError: tagsError } = useGetTagsQuery();

  if (tagsLoading) return <div>Loading tags...</div>;
  if (tagsError) return <div>Error loading tags.</div>;

  const tagsArray = Array.isArray(selectedTags) ? selectedTags : [];

  return (
    <FormControl sx={{ minWidth: 250, maxWidth: 350 }}>
      <InputLabel>Tags</InputLabel>
      <Select
        multiple
        value={tagsArray}
        label="Tags"
        onChange={(e) => setSelectedTags(e.target.value)}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {tagsArray.map((value) => (
              <Chip key={value} label={tags.find(tag => tag.id === value)?.name} />
            ))}
          </Box>
        )}
      >
        {tags.map((tag) => (
          <MenuItem key={tag.id} value={tag.id}>
            <Checkbox checked={tagsArray.indexOf(tag.id) > -1} />
            <ListItemText primary={tag.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Status Dropdown Component
export const StatusDropdown = ({ selectedStatus, setSelectedStatus }) => (
  <FormControl sx={{ minWidth: 250, maxWidth: 350 }}>
    <InputLabel>Status</InputLabel>
    <Select
      value={selectedStatus}
      onChange={(e) => setSelectedStatus(e.target.value)}
      label="Status"
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="pending">Pending</MenuItem>
      <MenuItem value="noshow">No Show</MenuItem>
      <MenuItem value="confirmed">Confirmed</MenuItem>
      <MenuItem value="canceled">Canceled</MenuItem>
      <MenuItem value="installed">Installed</MenuItem>
      <MenuItem value="alert">Alert</MenuItem>
      <MenuItem value="gone">Gone</MenuItem>
      <MenuItem value="unsatisfied">Insatisfait</MenuItem>
    </Select>
  </FormControl>
);
