import {
  CalendarMonth,
  Group,
  Groups,
  ListAlt,
  GroupAdd,
  MonetizationOn,
  Equalizer,
  History,
  Web,
  Groups2,
  GroupWorkTwoTone,
  Money,
} from "@mui/icons-material";
import ScheduleIcon from '@mui/icons-material/Schedule';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DateRangeIcon from '@mui/icons-material/DateRange';
// import { name } from "dayjs/locale/fr";

export const navigations = [
  {
    name: "Dashboard",
    path: "/statics",
    icon: Equalizer,
    roles: ["admin"],
  },

  {
    name: "Reservations",
    path: "/bookings",
    icon: CalendarMonth,
    roles: ["admin", "headsales", "restaurateur", "bureau"],
  },
  {
    name: "Resa Marketing",
    path: "/resa-dep-com",
    icon: DateRangeIcon,
    roles: ["admin","marketer"],
  },
  {
    name: "Resa Creation",
    path: "/resa-Creation",
    icon: ScheduleIcon,
    roles: ["admin", "marketer"],
  },
  {
    name: "Commerciaux",
    path: "/salespeople",
    icon: Groups,
    roles: ["admin", "headsales"],
  },
  {
    name: "Demandes de parternariat",
    path: "/partnership",
    icon: GroupWorkTwoTone,
    roles: ["admin", "headsales"],
  },

  {
    name: "Site Web",
    path: "/siteweb",
    icon: Web,
    roles: ["admin", "bureau", "restaurateur"],
  },

  {
    name: "Clients",
    path: "/clients",
    icon: Group,
    roles: ["admin"],
  },

  {
    name: "Partenaires",
    path: "/partners",
    icon: Groups2,
    roles: ["admin", "headsales"],
  },

  {
    name: "Utilisateurs",
    path: "/users",
    icon: GroupAdd,
    roles: ["admin"],
  },

  {
    name: "Caisse",
    path: "/orders",
    icon: ListAlt,
    roles: ["admin", "cashier"],
  },

  {
    name: "Informations",
    path: "/infos",
    icon: Web,
    roles: ["admin"],
  },

  {
    name: "Paiement Partenaires",
    path: "/partners-payments",
    icon: Money,
    roles: ["admin", "cashier"],
  },
  {
    name: "Campagnes marketing",
    path: "/campaigns",
    icon: MonetizationOn,
    roles: ["admin"],
  },
  {
    name: "Statistiques",
    path: "/statistics",
    icon: LeaderboardIcon,
    roles: ["admin"],
  },
  {
    name: "History",
    path: "/history",
    icon: History,
    roles: ["admin"],
  },
];
