import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  styled,

} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  CustomTable,
  FlexBox,
  H6,
  ListHeader,
  Paragraph,
  Small,
  Span,
  Tiny,
} from "components";
import { convertDate } from "utils/dateUtils";
import { useStatisticsQuery } from "store/statistics/services";
import { useUpdateBookingMutation } from "store/bookings/services";
import { CanalDropdown, TagsDropdown, StatusDropdown } from "./dropDownFilters";
import { Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Edit } from "@mui/icons-material";
import AddBooking from "../booking/AddBooking.jsx"
import ScrollToBottomButton from './ScrollToBottomButton.jsx';

dayjs.extend(utc);
const columnShape = [
  {
    Header: "Provenance",
    accessor: "user",
    Cell: ({ row }) => {
      const { user, client } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            {user && user.firstname ? (
              <Tiny color="text.disabled">{user.firstname}</Tiny>
            ) : (
              <Tiny color="text.disabled">WEB</Tiny>
            )}
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Client",
    accessor: "id",
    Cell: ({ row }) => {
      const { client } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{client?.fullname || "fullname"}</H6>
            <Paragraph color="text.secondary">{client?.phone}</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Tags",
    accessor: "tags",
    Cell: ({ row }) => {
      const { tags } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            {tags.map((tag) => (
              <H6 key={tag.id} color="text.primary">
                {tag.name}
              </H6>
            ))}
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Note",
    accessor: "note",
  },

  {
    Header: () => {
      return (
        <p>
          Arrivée
          <br />
          <Tiny color="text.secondary">(Création)</Tiny>
        </p>
      );
    },
    accessor: "checkin",
    Cell: ({ row }) => {
      const { checkin, createdAt } = row.original;
      return (
        <Span>
          {convertDate(checkin)}
          <br />
          <Tiny color="text.secondary">
            ({convertDate(createdAt, "number")})
          </Tiny>
        </Span>
      );
    },
  },

  {
    Header: "Statut",
    minWidth: " 110px",
    accessor: "status",
    Cell: ({ row }) => {
      const { status } = row.original;
      const getStatus = (val) => {
        switch (val) {
          case "canceled":
            return "Annulé";
          case "confirmed":
            return "Confirmé";
          case "installed": //to be checked
            return "Installé";
          case "alert":
            return "Alerte";
          case "gone":
            return "Reparti";
          case "noshow":
            return "No Show";
          default:
            return "En attente";
        }
      };
      return (
        <Small
          sx={{
            width: 100,
            fontSize: 14,
            color: "black",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor:
              status === "canceled"
                ? "#9e9e9e"
                : status === "confirmed"
                  ? "#4caf50"
                  : status === "installed"
                    ? "#2196f3"
                    : status === "gone"
                      ? "#ffffff"
                      : status === "alert"
                        ? "#f44336"
                        : status === "noshow"
                          ? "#F5EEC8"
                          : "#ff9933",
          }}
        >
          {getStatus(status)}
        </Small>
      );
    },
  },
  {
    Header: "Table",
    accessor: "table",
    Cell: ({ row }) => {
      const { tables } = row.original;
      return tables.map((table) => (
        <Span key={table.id}>
          {table.tableRef}
          <br />
        </Span>
      ));
    },
  },
  {
    Header: "Personnes",
    accessor: "persons",
    Cell: ({ row }) => {
      const { adults, children } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{adults} Adultes</H6>
            <Paragraph color="text.secondary">{children} Enfants</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Canal",
    accessor: "canal",
    Cell: ({ row }) => {
      const { canal } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{canal || "googleAds"}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },
   {
    Header: "Addition",
    accessor: "amount",
    Cell: ({ row }) => {
      const { amount } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{amount}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Modifier",
    id: "modifier",
    accessor: "id",
    Cell: ({ row }) => {
      const [openModal, setOpenModal] = useState(false);
      return (
        <>
          <IconButton onClick={() => setOpenModal(true)}>
            <Edit
              sx={{
                color: "text.disabled",
                fontSize: 18,
              }}
            />
          </IconButton>
          <AddBooking
            edit
            open={openModal}
            data={row.original}
            onClose={() => setOpenModal(false)}
          />
        </>
      );
    },
  },
];
const columnShape2 = [
  {
    Header: "Etablissement",
    accessor: "establishmentType",
    Cell: ({ row }) => {
      const { Partner } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{Partner.establishmentType}</H6>
            <H6>{Partner.establishmentName}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Partenaire",
    accessor: "PartnerName",
    Cell: ({ row }) => {
      const { Partner } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{Partner.name}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Note",
    accessor: "note",
  },

  {
    Header: () => {
      return <p>Arrivée</p>;
    },
    accessor: "checkin",
    Cell: ({ row }) => {
      const { checkin } = row.original;
      return <Span>{convertDate(checkin)}</Span>;
    },
  },

  {
    Header: "Statut",
    minWidth: " 110px",
    accessor: "status",
    Cell: ({ row }) => {
      const { status } = row.original;
      const getStatus = (val) => {
        switch (val) {
          case "canceled":
            return "Annulé";
          case "confirmed":
            return "Confirmé";
          case "installed":
            return "Installé";
          case "alert":
            return "Alerte";
          case "gone":
            return "Reparti";
          case "unsatisfied":
            return "Insatisfait";
          default:
            return "En attente";
        }
      };
      return (
        <Small
          sx={{
            width: 100,
            fontSize: 14,
            color: "black",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor:
              status === "canceled"
                ? "#9e9e9e"
                : status === "confirmed"
                  ? "#4caf50"
                  : status === "installed"
                    ? "#2196f3"
                    : status === "gone"
                      ? "#ffffff"
                      : status === "alert"
                        ? "#f44336"
                        : status === "noshow"
                          ? "#F5EEC8"
                          : "#ff9933",
          }}
        >
          {getStatus(status)}
        </Small>
      );
    },
  },
  {
    Header: "Table",
    accessor: "table",
    Cell: ({ row }) => {
      const { tables } = row.original;
      return tables.map((table) => (
        <Span key={table.id}>
          {table.tableRef}
          {"\n"}
        </Span>
      ));
    },
  },

  {
    Header: "Personnes",
    accessor: "persons",
    Cell: ({ row }) => {
      const { adults, children } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{adults} Adultes</H6>
            <Paragraph color="text.secondary">{children} Enfants</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Addition",
    accessor: "amount",
    Cell: ({ row }) => {
      const { amount } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{amount}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },

];

const StyledRectangle = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFC664",
  color: "black",
  padding: theme.spacing(1, 2), // Adjust padding for better responsiveness
  borderRadius: 5,
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%", // Ensures the component takes full width of its container
  boxSizing: "border-box", // Ensures padding is included in the width calculation

  [theme.breakpoints.down("sm")]: {
    // Adjust styles for small screens
    padding: theme.spacing(1), // Reduce padding on smaller screens
  },

  [theme.breakpoints.up("md")]: {
    // Adjust styles for medium and larger screens
    padding: theme.spacing(1, 3), // Adjust padding as needed
  },
}));

const StyledRectangle2 = styled(Box)(({ theme }) => ({
  backgroundColor: "#1A2130",
  color: "white",
  padding: theme.spacing(1, 2), // Adjust padding for better responsiveness
  borderRadius: 5,
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%", // Ensures the component takes full width of its container
  boxSizing: "border-box", // Ensures padding is included in the width calculation

  [theme.breakpoints.down("sm")]: {
    // Adjust styles for small screens
    padding: theme.spacing(1), // Reduce padding on smaller screens
  },

  [theme.breakpoints.up("md")]: {
    // Adjust styles for medium and larger screens
    padding: theme.spacing(1, 3), // Adjust padding as needed
  },
}));

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
const Statistics = () => {

  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const [isPartnerCollapsed, setIsPartnerCollapsed] = useState(false);
  const handlePartnerToggle = () => {
  setIsPartnerCollapsed(!isPartnerCollapsed);
  };
  const [selectedTags, setSelectedTags] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedCanal, setSelectedCanal] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataPartner, setFilteredDataPartner] = useState([])
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 600);
  const queryParams = {};
  if (debouncedSearchValue) queryParams.searchQuery = debouncedSearchValue;
  if (dateRange.startDate !== dayjs().format("YYYY-MM-DD")) queryParams.startDate = dateRange.startDate;
  if (dateRange.endDate !== dayjs().format("YYYY-MM-DD")) queryParams.endDate = dateRange.endDate;

  const { data, error, isLoading, isFetching } = useStatisticsQuery(
    Object.keys(queryParams).length > 0 ? queryParams : undefined,
    { enabled: true }
  );

  const partnerBookings = data?.partnerBookings;
  const partnerTotals = data?.partnerTotals;

  const [filteredTotals, setFilteredTotals] = useState({
    totalAdults: 0,
    totalKids: 0,
    totalAmount: 0,
    totalCount: 0,
    totalAdultsC: 0,
    totalKidsC: 0,
    totalAmountC: 0,
    totalCame: 0,
  });

  const [ftParnerTotals, setftParnerTotals] = useState({
    totalAdults: 0,
    totalKids: 0,
    totalAmount: 0,
    totalCount: 0,
    totalAdultsC: 0,
    totalKidsC: 0,
    totalAmountC: 0,
    totalCame: 0,
  });

  const calculateTotals = (bookings) => {
  let totals = {
    totalAdults: 0,
    totalKids: 0,
    totalAmount: 0,
    totalCount: 0,
    totalAdultsC: 0,
    totalKidsC: 0,
    totalAmountC: 0,
    totalCame: 0,
  };
  bookings.forEach((booking) => {
    if (booking.status !== 'canceled') {
      totals.totalAdults += booking.adults || 0;
      totals.totalKids += booking.children || 0;
      totals.totalAmount += booking.amount || 0;
      totals.totalCount += 1;
    }
    if (booking.status === 'confirmed' || booking.status === 'installed' || booking.status === 'gone') {
      totals.totalAdultsC += booking.adults || 0;
      totals.totalKidsC += booking.children || 0;
      totals.totalAmountC += booking.amount || 0;
      totals.totalCame += 1;
    }
  });
  return totals;
};
  useEffect(() => {
    if (!data?.bookings) return;
    const partnerBookings = data.partnerBookings;
    const partnerTotals = data.partnerTotals;
    const filteredBookings = data.bookings.filter((item) => (
      (selectedCanal ? item.canal === selectedCanal : true) &&
      (selectedStatus ? item.status === selectedStatus : true) &&
      (selectedTags.length
        ? item.tags.some((tag) => selectedTags.includes(tag.id))
        : true)
    ));
    const filteredPartner = data.partnerBookings.filter((item) => (
      (selectedCanal ? item.canal === selectedCanal : true) &&
      (selectedStatus ? item.status === selectedStatus : true) &&
      (selectedTags.length
        ? item.tags.some((tag) => selectedTags.includes(tag.id))
        : true)
    ));
    setFilteredData(filteredBookings);
    setFilteredDataPartner(filteredPartner);
    console.log(filteredDataPartner)
    setFilteredTotals(calculateTotals(filteredBookings));
    setftParnerTotals(calculateTotals(filteredPartner));
    setIsFiltersActive(
      selectedCanal || selectedStatus || selectedTags.length > 0
    );
  }, [
    data,
    selectedCanal,
    selectedTags,
    selectedStatus,
  ]);
  if ((isLoading || isFetching) && !data) {
    return <div>
      Fetching Data
    </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.bookings) {
    return <div>No data available</div>;
  }

  const tnc = data?.totalCount + data?.partnerTotals.totalCount;
  const tc = data?.totalCame + data?.partnerTotals.totalCame;
  const tac = data?.totalAdults + data?.partnerTotals.totalAdults;
  const ta = data?.totalAdultsC + data?.partnerTotals.totalAdultsC;
  const tkc = data?.totalKids + data?.partnerTotals.totalKids;
  const tk = data?.totalKidsC + data?.partnerTotals.totalKidsC;
  const tpx = tk + ta;
  const tpxc = tkc + tac;
  const tmadc = data?.totalAmountC + data?.partnerTotals.totalAmountC;
  const tmad = data?.totalAmount + data?.partnerTotals.totalAmount;

  return (
    <Box pt={2} pb={4}>
      <ListHeader
        setDateValue={setDateRange}
        setSearchValue={setSearchValue}
      />

      <Box
        mb={2}
        display="flex"
        gap={2}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <CanalDropdown
          selectedCanal={selectedCanal}
          setSelectedCanal={setSelectedCanal}
        />
        <TagsDropdown
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <StatusDropdown
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </Box>
        <ScrollToBottomButton />
      {filteredDataPartner &&(
        <>
          <StyledRectangle>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6">Réservations Partenaire</Typography>
              <IconButton onClick={handlePartnerToggle}>
              {isPartnerCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
          </StyledRectangle>
          <Collapse in={!isPartnerCollapsed}>
          <Box>
            <CustomTable
              data={{
                data: [...filteredDataPartner],
              }}
              columnShape={columnShape2}
              hidePagination={true}
            />
          </Box>

          <Box
            p={2}
            bgcolor="#FFc200"
            borderRadius={2}
            mb={2}
            mt={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{
              "& > *": {
                color: "#ffffff",
              },
            }}
          >
          <Typography>Total Réservations: {" "} {isFiltersActive ? ftParnerTotals.totalCount : partnerTotals?.totalCount}</Typography>
          <Typography>Adultes: {" "} {isFiltersActive ? ftParnerTotals.totalAdults : partnerTotals?.totalAdults}</Typography>
          <Typography>Enfants: {" "} {isFiltersActive ? ftParnerTotals.totalKids : partnerTotals?.totalKids}</Typography>
          <Typography>PAX: {" "} {isFiltersActive ? ftParnerTotals.totalKids + ftParnerTotals.totalAdults : partnerTotals?.totalKids + partnerTotals?.totalAdults}</Typography>
          <Typography>CA: {" "} {isFiltersActive ? ftParnerTotals.totalAmount.toFixed(2) : partnerTotals.totalAmount.toFixed(2)} MAD</Typography>
          </Box>
            <Box
            p={2}
            bgcolor="#FFB700"
            borderRadius={2}
            mb={2}
            mt={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{
              "& > *": {
                color: "#ffffff",
              },
            }}
          >
          <Typography>Total Réservations: {" "} {isFiltersActive ? ftParnerTotals.totalCame : partnerTotals?.totalCame}</Typography>
          <Typography>Adultes: {" "} {isFiltersActive ? ftParnerTotals.totalAdultsC : partnerTotals?.totalAdultsC}</Typography>
          <Typography>Enfants: {" "} {isFiltersActive ? ftParnerTotals.totalKidsC : partnerTotals?.totalKidsC}</Typography>
          <Typography>PAX: {" "} {isFiltersActive ? ftParnerTotals.totalKidsC + ftParnerTotals.totalAdultsC : partnerTotals?.totalKidsC + partnerTotals?.totalAdultsC}</Typography>
          <Typography>CA: {" "} {isFiltersActive ? ftParnerTotals.totalAmountC.toFixed(2) : partnerTotals.totalAmountC.toFixed(2)} MAD</Typography>
          </Box>
          </Collapse>
        </>
      )}
      <StyledRectangle2 mt={5}>
         <Box display="flex" alignItems="center">
              <Typography variant="h6">Autres Réservations</Typography>
              <IconButton onClick={handleToggleCollapse}>
                {isCollapsed ? <ExpandMoreIcon style={{ color: "white" }} /> : <ExpandLessIcon style={{ color: "white" }} />}
              </IconButton>
            </Box>
      </StyledRectangle2>
      <Collapse in={!isCollapsed}>
      {filteredData && (
        <CustomTable
          data={{
            data: filteredData.sort(
              (a, b) => new Date(a.checkin) - new Date(b.checkin)
            ),
          }}
          hidePagination={true}
          columnShape={columnShape}
        />
      )}

      <Box
        p={2}
        bgcolor="#433D8B"
        borderRadius={2}
        mb={2}
        mt={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          "& > *": {
            color: "#ffffff",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{ flexGrow: 1, ml: 2 }}
        >
          <Typography>Total Réservations: {" "} {isFiltersActive ? filteredTotals.totalCount : data?.totalCount}</Typography>
          <Typography>Adultes: {" "} {isFiltersActive ? filteredTotals.totalAdults : data?.totalAdults}</Typography>
          <Typography>Enfants: {" "} {isFiltersActive ? filteredTotals.totalKids : data?.totalKids}</Typography>
          <Typography>PAX: {" "} {isFiltersActive ? filteredTotals.totalKids + filteredTotals.totalAdults : data?.totalKids + data?.totalAdults}</Typography>
          <Typography>CA: {" "} {isFiltersActive ? filteredTotals.totalAmount.toFixed(2) : data?.totalAmount.toFixed(2)} MAD</Typography>
        </Box>
      </Box>

      <Box
        p={2}
        bgcolor="#3F3786"
        borderRadius={2}
        mb={2}
        mt={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          "& > *": {
            color: "#ffffff",
          },
        }}
      >
          <Typography>Réservations Actives: {" "} {isFiltersActive ? filteredTotals.totalCame : data?.totalCame}</Typography>
          <Typography>Adultes: {" "} {isFiltersActive ? filteredTotals.totalAdultsC : data?.totalAdultsC}</Typography>
          <Typography>Enfants: {" "} {isFiltersActive ? filteredTotals.totalKidsC : data?.totalKidsC}</Typography>
          <Typography>PAX: {" "} {isFiltersActive ? filteredTotals.totalKidsC + filteredTotals.totalAdultsC : data?.totalKidsC + data?.totalAdultsC}</Typography>
          <Typography>CA: {" "} {isFiltersActive ? filteredTotals.totalAmountC.toFixed(2) : data?.totalAmountC.toFixed(2)} MAD</Typography>
      </Box>
      </Collapse>
       <StyledRectangle>
              <Typography variant="h6">Réservations Overview</Typography>
          </StyledRectangle>
      <Box
        p={2}
        bgcolor="#5c5c5c"
        borderRadius={2}
        mb={2}
        mt={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          "& > *": {
            color: "#ffffff",
          },
        }}
      >
          <Typography>
    Total Reservations:{" "}
    {isFiltersActive ? filteredTotals.totalCount + ftParnerTotals.totalCount : tc}
  </Typography>
  <Typography>
    Adults:{" "}
    {isFiltersActive ? filteredTotals.totalAdults + ftParnerTotals.totalAdults : tac}
  </Typography>
  <Typography>
    Children:{" "}
    {isFiltersActive ? filteredTotals.totalKids + ftParnerTotals.totalKids : tkc}
  </Typography>
  <Typography>
    PAX:{" "}
    {isFiltersActive ? (filteredTotals.totalAdults + ftParnerTotals.totalAdults) + (filteredTotals.totalKids + ftParnerTotals.totalKids) : tpxc}
  </Typography>
  <Typography>
    Revenue:{" "}
    {isFiltersActive ? (filteredTotals.totalAmount + ftParnerTotals.totalAmount).toFixed(2) : tmad.toFixed(2)} MAD
  </Typography>
      </Box>
      <Box
            p={2}
            bgcolor="#7f7f7f"
            borderRadius={2}
            mb={2}
            mt={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{
              "& > *": {
                color: "#ffffff",
              },
            }}
          >
           <Typography>
    Total Reservations:{" "}
    {isFiltersActive ? filteredTotals.totalCame + ftParnerTotals.totalCame : tc}
  </Typography>
  <Typography>
    Adults:{" "}
    {isFiltersActive ? filteredTotals.totalAdultsC + ftParnerTotals.totalAdultsC : ta}
  </Typography>
  <Typography>
    Children:{" "}
    {isFiltersActive ? filteredTotals.totalKidsC + ftParnerTotals.totalKidsC : tk}
  </Typography>
  <Typography>
    PAX:{" "}
    {isFiltersActive ? (filteredTotals.totalAdultsC + ftParnerTotals.totalAdultsC) + (filteredTotals.totalKidsC + ftParnerTotals.totalKidsC) : tpx}
  </Typography>
  <Typography>
    Revenue:{" "}
    {isFiltersActive ? (filteredTotals.totalAmountC + ftParnerTotals.totalAmountC).toFixed(2) : tmadc.toFixed(2)} MAD
  </Typography>
          </Box>
    </Box>
  );
};

export default Statistics;
