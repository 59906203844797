import { useState, useEffect ,React } from 'react';
import { Fab } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToBottomButton = () => {
  const [visible, setVisible] = useState(false);
  const [scrollingToBottom, setScrollingToBottom] = useState(false);

  const handleScrollToggle = () => {
    if (scrollingToBottom) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
    setScrollingToBottom(!scrollingToBottom);
  };

  const handleScroll = () => {
    const isVisible = window.scrollY > 100;
    setVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    visible && (
      <Fab
        onClick={handleScrollToggle}
        style={{
          position: 'fixed',
          bottom: '16px',
          right: '16px',
          backgroundColor: '#FF6700',
          color: 'white',
        }}
        aria-label={scrollingToBottom ? 'scroll to top' : 'scroll to bottom'}
      >
        {scrollingToBottom ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      </Fab>
    )
  );
};

export default ScrollToBottomButton;

